import { russianDefaultPaymentCards } from "app/constants/CurrencyConstants";
import { zgkCitesOptions } from "../../../zgkostrovskogo/js/app/zgkConstants";

export const reducer: typeof window.kinosite.reducer  = {
	cinema: {
		address: ' ул. Энтузиастов 11, р4',
		place: null,
		phoneNumbers: [],
		facebook: null,
		instagram: null,
		vk: null,
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: russianDefaultPaymentCards,
		},
		maps: null,
		selectButton: {
			options: zgkCitesOptions,
			selectedOptionValue: '//восток.кино-75.рф',
		},
		soonPageIsEnabled: false,
	},
	feedback: true,
};
